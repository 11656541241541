import React from 'react';
import ReactTypingEffect from 'react-typing-effect';

const NotYet = () => (
  <ReactTypingEffect
    className="message"
    text={
      [
        "We know the cloud can feel overwhelming.",
        "It’s full of technical jargon and complex services.",
        "But at the heart of it all, we believe it’s really about people—about making life a little easier for you and your business.",
        "We will not be the biggest cloud provider out there,",
        "and we’re okay with that.",
        "What matters to us is being a team you can trust, with people who genuinely care about your success.",
        "We’re here to offer simple, reliable cloud services that work for you, without the fuss.",
        "When you choose us, you're not just choosing another provider.",
        "You're choosing people who are ready to listen, to support you when you need it, and to grow alongside you—no matter how big or small your journey might be.",
        "No fancy promises. No hidden agendas. Just real people, ready to help.",
        "Cheers,",
        "The Linka Cloud Team",
      ]
    }
    speed={100}
    eraseDelay={3000}
    eraseSpeed={10}
    typingDelay={1000}
  />
)

function App() {
  return (
    <NotYet/>
  );
}

export default App;
